// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData,getStorageData } from "../../../framework/src/Utilities";
import { downloadFile } from "../../../components/src/downloadFile";

export interface HighlightNewData {
  quote: {
    product: string,
    unit_price: string,
    ext_price: string,
    ord_quantity: number,
    unit: string
  },
  invoice: {
    product: string,
    unit_price: string,
    ext_price: string,
    ord_quantity: number,
    order_unit: string,
    report_invoice_id: number
  },
  product: string;
  type: string
}
export interface DataItem {
  id: number;
  description: string;
  ord_quantity: number;
  unit: string;
  price: string;
  amount: string;
  report_quote_id: number;
  created_at:string;
  updated_at: string;
  part_number: string | null;
  tax: string | null;
  category_name: string;
  category_subtotal: number;
  rate_sheet_cat: string | null;
  rate_sheet_min: string | null;
  rate_sheet_daily: string | null;
  rate_sheet_weekly: string | null;
  rate_sheet_monthly: string | null;
  cat_class: string | null;
}
export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Test {
  quote: {
    product: string,
    unit_price: string,
    ext_price: string,
    ord_quantity: number,
    unit: string
  },
  invoice: {
    product: string,
    unit_price: string,
    ext_price: string,
    ord_quantity: number,
    unit: string
  },
  color: string,
  total: number,
  type: string
}

interface Summary {
  report: string,
  vendor: string,
  project: string,
  total_discrepancy_undercharged: number,
  total_discrepancy_overcharged: number,
  highlighted_product: Test[]
}

interface Quote {
  product: string;
  unit_price: string;
  ext_price: string;
  ord_quantity: number;
  unit: string;
}

interface Invoice {
  product: string;
  unit_price: string;
  ext_price: string;
  ord_quantity: number;
  unit: string;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
}
interface DashboardItem {
  quote: Quote;
  invoice: Invoice;
  color: string;
  total: number;
  type: string;
}
interface DiscrepancyTypeOption {
  type: string; 
  label: string;
  selected: boolean;
  pdf_url?: string[]; 
}
interface Attachment {
  name: string;
  size: string;
}
interface VendorDetail {
  to: string;
  subject: string;
  message: string;
  total_discrepancy: string;
  discrepancy_type_options: DiscrepancyTypeOption[];
  vendor_name: string;
  status: 'sent' | 'failed'; 
  sent_at_date: string;
  sent_at_time: string;
  attachments: Attachment[];
}
// Customizable Area End

export const configJSON = require("./config");
interface CustomPage {
  getTextContent(): Promise<{ items: string[] | null }>;
}
export interface Props {
  // Customizable Area Start
  classes: any;
  history: any;
  navigation: any;
  handleSaveData: (data: any) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reportList: any,
  report_ID: number,
  projectValue: number,
  pdfSelectedRadioValue:string,
  checkedBox:boolean,
  payReportid:string,
  reportValue: string,
  vendorValue: number,
  id: any,
  isLoading: any,
  isNoDataAvailable: boolean;
  project_name: any;
  project_id: any;
  loadingVendorData: any;
  vendorChangeReportData: any;
  series: any;
  discrepancyArray: any;
  totalUniqueVendor: any;
  filteredVendorList: any;
  toggleValue: any;
  fullViewScreen: any;
  halfViewScreen: any;
  reportDetailsData: any;
  quoteValue: any;
  quotesTabs: any;
  quoteUrl: any;
  pageNumberQuote: any;
  minPage: any;
  numPagesQuote: any;
  quotePdfpage: any;
  invoiceValue: any;
  pageNumberInvoice: any;
  uploadAcceptedFile: any;
  isLoadingg: boolean;
  invoiceUrl: any;
  invoiceData: any;
  invoiceItems: any;
  segmentTotal2: any;
  quoteCategoryDescription: any;
  segmentName1: any;
  segmentTotal1: any;
  segmentName2: any;
  quotePage: any;
  invoicePage: any;
  columns: any;
  quoteData: any;
  reportName: any;
  highlightData: []
  reportDataid: number,
  textItems: string[] | null,
  invoiceSize:number,
  quoteSize:number,
  highlightedProductsMap: any
  files: Array<File>;
  openModal: boolean;
  selectedValues: string[];
  anchorEl: null | HTMLElement;
  isOptionMenu: boolean;
  dummyData: Summary;
  selectedValue: string;
  viewListData: DashboardItem[];
  dowmloadModal: boolean;
  showPdf: boolean;
  emailName: string;
  emailSubject: string;
  emailMessage: string; 
  emailDiscripencyType: string;
  emailNameCancle: string,
  emailSubjectCancle: string,
  emailMessageCancle: string,
  isDisableBtn: boolean;
  invalidEmail: string;
  emailConfirm: boolean;
  toastMessage: boolean;
  vendorName: string;
  tabValue: number;
  selectedOption: string;
  apiSelectedOption: string;
  selectedDownloadOption: string;
  apiSelectedDownloadOption: string;
  pdfUrl: string
  pdfName: string
  invoicePdfUrl: string
  quotePdfUrl: string
  activeTab: null | number;
  invoiceDown: boolean;
  quoteDown: boolean;
  vendorDetails: VendorDetail[];
  lastedEmail: string;
  paginationArr:any,
  invoiceDescriptionGroup:any,
  paginationKey:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export interface QuotePDFDocumentProps {
  invoiceUrl: any,
  classes: any,
  pageNumberInvoice: number,
  onPageLoadSuccess: (page: CustomPage) => void,
  highlightData: any,
  textItems: any
  fullScreen?:any,
  invoiceSize?:number,
  quoteSize?:number,
}

export interface InvoicePDFDocumentProps extends QuotePDFDocumentProps {
  invoiceValue: number;
}

export interface TextItem {
  str: string;
}

export interface HighlightData {
  product: string;
  color: string;
  unit: string;
  ext_price: string;
  ord_quantity: string;
  unit_price: string
}

export default class ViewReportDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  discrepancyDataApiCallId: string = "";
  viewReportApiCallID = "";
  approvedpaymentApiCallid: string = "";
  uploadReportInvoicesFileId = "";
  getIpApiCallId: string = "";
  filterApiCallId: string = "";
  getEmailData: string = "";
  getSendEmailData: string = "";
  sendEmailApiCallId: string = "";
  addVendorsId: string = "";
  downloadSummaryPage = "";
  downloadQuote = "";
  downloadQuotePdf = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      payReportid:'',
      pdfSelectedRadioValue:'A day',
      checkedBox:false,
      reportList: [],
      report_ID:1,
      id: '',
      projectValue: 1,
      reportValue: '',
      vendorValue: 1,
      isLoading: false,
      isNoDataAvailable: true,
      vendorChangeReportData: {},
      series: [20, 50],
      discrepancyArray: [],
      totalUniqueVendor: 0,
      filteredVendorList: [],
      project_name: "",
      reportName: "",
      project_id: 0,
      loadingVendorData: false,
      toggleValue: false,
      fullViewScreen: "",
      halfViewScreen: "",
      reportDetailsData: null,
      quoteValue: 0,
      quotesTabs: [{ project: "Quote" }],
      pageNumberQuote: 1,
      minPage: 0,
      numPagesQuote: 0,
      quotePdfpage: 0,
      invoiceValue: 0,
      pageNumberInvoice: 1,
      uploadAcceptedFile: [],
      isLoadingg:false,
      quoteUrl: "",
      invoiceUrl: [
      ],
      invoiceData: [
      ],
      invoiceItems: [],
      segmentTotal2: 0,
      quoteCategoryDescription: [{
        id: 1,
        description: "4X14 SDR26 PVC SEWER PIPE GASKETED",
        price: "$ 958.32",
        quantity: 198,
        unit: "ft",
        unit_price: "$ 4.84"
      }, {
        id: 2,
        description: "4X14 SDR26 PVC SEWER PIPE GASKETED",
        price: "$ 958.32",
        quantity: 198,
        unit: "ft",
        unit_price: "$ 4.84"
      }],
      segmentName1: "",
      segmentTotal1: 0,
      segmentName2: "",
      quotePage: 1,
      invoicePage: 1,
      columns: [
        {
          id: 'Sr.no', label: 'Sr. No',
        },
        {
          id: 'description', label: 'Description',
        },
        {
          id: 'OrderQty.', label: 'Order Qty.',
        },
        {
          id: 'unit', label: 'Unit',
        },
        {
          id: 'unitPrice', label: 'Unit Price',
        },
        {
          id: 'Ext. Price', label: 'Ext. Price'
        }
      ],
      dummyData: {
        report: "",
        vendor: "",
        project: "",
        total_discrepancy_undercharged: 0,
        total_discrepancy_overcharged: 0,
        highlighted_product: []
      },
      viewListData: [],
      quoteData: {
        quoteTableData: [{
          id: 1,
          description: "4X14 SDR26 PVC SEWER PIPE GASKETED",
          price: "$ 958.32",
          quantity: 198,
          unit: "ft",
          unit_price: "$ 4.84"
        }],

        quotedetails: {
          created_at: "2023-11-21T05:44:54.450Z",
          expiration_date: null,
          discount: 0,
          date: "2023-04-19",
          id: 1360,
          other_charges: 0,
          project: "UNDERGROUND REPUBLIC WATER WORKS",
          quote_id: "S100006857",
          s_and_h_charges: 0,
          site_address: "345 ARCADIA AVE.MORRO BAY, CA 93442",
          site_contact: null,
          site_contactperson: "Unknown",
          site_name: "DaSILVA UNDERGROUND CONSTRUCTION",
          status: "rejected",
          subtotal: 7758.75,
          supplier_address: "1175 CAMPBELL AVE SAN JOSE, CA 95126",
          supplier_contact: "408-714-0047",
          supplier_salesperson: "Unknown",
          tag: "Report",
          tax: 0,
          tax_rate_amount: 0,
          total_price: 7758.75,
          updated_at: "2023-11-21T05:45:57.013Z",
          vendor: "DaSILVA UNDERGROUND CONSTRUCTION",
        }
      },
      highlightData: [],
      reportDataid: 0,
      textItems: null,
      invoiceSize:612,
      quoteSize:612,
      highlightedProductsMap: new Map(),
      files: [],
      openModal: false,
      selectedValues: ["overcharge_only", "quote_highlighting", "invoice_highlighting"],
      anchorEl: null,
      isOptionMenu: false,
      selectedValue: "both",
      dowmloadModal: false,
      showPdf: false,
      emailName: "",
      emailSubject: "",
      emailMessage: "",
      emailDiscripencyType: "",
      isDisableBtn: true,
      emailNameCancle: "",
      emailSubjectCancle: "",
      emailMessageCancle: "",
      invalidEmail: "",
      emailConfirm: false,
      toastMessage: false,
      vendorName: "",
      tabValue: 0,
      selectedOption: "",
      apiSelectedOption: "",
      selectedDownloadOption: "",
      apiSelectedDownloadOption: "",
      pdfUrl: "",
      invoicePdfUrl :"",
      quotePdfUrl: "",
      pdfName: "",
      activeTab: 0,
      invoiceDown: false,
      quoteDown: false,
      vendorDetails: [],
      lastedEmail: "",
      paginationArr:[],
      invoiceDescriptionGroup:[],
      paginationKey:"invoice_1_pagination_1"
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  getToken = () => {
    return document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
  }

  async componentDidMount() {
    super.componentDidMount();
    const goToReportDetails = await getStorageData("goToReport");
    const goToReport = JSON.parse(goToReportDetails);
    if (goToReport) {
      this.setState({ project_name: goToReport.projectName, reportName: goToReport.reportName, project_id: goToReport.reportId });
      this.viewReportDetails(JSON.parse(goToReport.reportId));
    } else {
      const itemViewData= await getStorageData("dataViewreport");
      const parseItemsdata = JSON.parse(itemViewData);
      this.setState({
        project_name: parseItemsdata.project, project_id: parseItemsdata.id, reportName: parseItemsdata.reportName
      })
      this.viewReportDetails(parseItemsdata.id)
      this.discrepancyApiCall(0)
      this.getSummary(parseItemsdata.id)
      this.getEmailList(parseItemsdata.id);
      this.getSendEmailList(parseItemsdata.id);
    }

    setTimeout(()=>{
      this.createPaginationArr(this.state.invoiceDescriptionGroup)
    },1000)

  }

  setHighlightedProductsMap = (newMap: any) => {
    this.setState({ highlightedProductsMap: newMap });
  }




  goBack = () => {
    window.history.back();
  };

  async receive(from: string, message: Message) {


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.viewReportApiCallID) {
        this.setState({
          reportDetailsData: responseJson,
          vendorChangeReportData: responseJson,
          isNoDataAvailable: false,
          loadingVendorData: false,
          isLoadingg : false,
          report_ID:responseJson.reports_details.data.id,
          reportValue: responseJson.reports_details.data.attributes.report_name,
          projectValue:responseJson.reports_details.data.attributes.project,
          vendorValue:responseJson.reports_details.data.attributes.vendor,
          quoteData: {
            quotedetails: responseJson.reports_details.data.attributes.quote[0],
            quoteTableData: responseJson.reports_details.data.attributes.quote_description,
          },
          quoteUrl: responseJson.quote_url,
          invoiceUrl: responseJson.invoice_pdf_url,
          quotesTabs: responseJson.reports_details.data.attributes.quote.length > 0 ? responseJson.reports_details.data.attributes.quote : [{ project: "Quote" }],
          invoiceData: responseJson.invoices_details,
          invoiceItems: responseJson.invoice_description,
          highlightData: responseJson.highlighted_product,
          quotePdfpage: responseJson.reports_details.data.attributes.quote[0]?.pdf_page_count,
          payReportid:  this.reportIdFun(responseJson),
          checkedBox: this.checkBoxFun(responseJson),
          invoiceDescriptionGroup:responseJson.invoice_description_group
        }, () => {
          localStorage.removeItem("goToReport");
          
        })
        this.handleSeriesDataFormatting(responseJson)
      }else if(apiRequestCallId ===this.uploadReportInvoicesFileId){
        const itemViewData= await getStorageData("dataViewreport");
        const parseItemsdata = JSON.parse(itemViewData);
        this.viewReportDetails(parseItemsdata.id ? parseItemsdata.id : parseItemsdata.reportName)
        if(responseJson){
          this.setState({isLoadingg :false})
        }
      }
      else if(apiRequestCallId === this.approvedpaymentApiCallid){
         this.viewReportDetails(this.state.project_id)
      }
      else if (apiRequestCallId === this.getIpApiCallId) {
        if (!responseJson.error) {
          this.setState({ dummyData: responseJson });
          this.setState({ viewListData: responseJson.highlighted_product });
        }
      }
    this.fiterResponseHandle(apiRequestCallId,responseJson)
      this.EmailResponseHandle(apiRequestCallId, responseJson);
      this.sendEmailHandler(apiRequestCallId, responseJson);
      this.addVendorHandler(apiRequestCallId, responseJson);
      this.sendEmailResponse(apiRequestCallId, responseJson);
      this.downloadSummaryResponse(apiRequestCallId, responseJson);
      this.downloadPdfResponse(apiRequestCallId, responseJson);
      this.downloadPdfQuote(apiRequestCallId, responseJson);
    }
  }

fiterResponseHandle = async (apiRequestCallId : string,responseJson:{filtered_project_dashboard_data:DashboardItem[]}) =>{
  if (apiRequestCallId === this.filterApiCallId) {
      if(responseJson){
        this.setState({ viewListData: responseJson.filtered_project_dashboard_data });
      }
  }
};

addVendorHandler = async (apiRequestCallId: string, responseJson: {[key:string]:string}) => {
  if (apiRequestCallId === this.addVendorsId) {
    if (!responseJson.error) {
      this.setState({ openModal: true, emailConfirm: false });
      this.getEmailList(this.state.project_id);
    }
  }
};

  sendEmailHandler = async (apiRequestCallId: string, responseJson: {[key:string]:string} ) => {
    if (apiRequestCallId === this.sendEmailApiCallId) {
      if (responseJson) {
        this.setState({ invalidEmail: responseJson.error });
        this.setState({isDisableBtn: false});
        if(responseJson.success){
          this.setState({ openModal: false });
          this.setState({ toastMessage: true });
          this.setState({ emailSubject: this.state.emailSubjectCancle });
          this.setState({ emailName: this.state.emailNameCancle });
          this.setState({ emailMessage: this.state.emailMessageCancle });
          this.setState({selectedValues: ["overcharge_only", "quote_highlighting", "invoice_highlighting"]})
          this.getSendEmailList(this.state.project_id);
          this.getEmailList(this.state.project_id);
        }
        if (responseJson.unique) {
          this.setState({ emailConfirm: true, openModal: false, invalidEmail: "" });
        }
      }
    }
  };

  EmailResponseHandle = async (apiRequestCallId: string, responseJson: {[key:string]:string}) => {
  if (apiRequestCallId === this.getEmailData) {
    if (responseJson) {
      this.setState({ emailSubject: responseJson.subject },()=>this.updateButtonState());
      this.setState({ emailName: responseJson.to },()=>this.updateButtonState());
      this.setState({ emailMessage: responseJson.message },()=>this.updateButtonState());
      this.setState({ emailSubjectCancle: responseJson.subject });
      this.setState({ emailNameCancle: responseJson.to });
      this.setState({ emailMessageCancle: responseJson.message });
      this.setState({vendorName: responseJson.vendor_name });
    }
  }
};
  sendEmailResponse = async (apiRequestCallId: string, responseJson: { emails: Array<VendorDetail> } & { error: Array<string> } & { latest_sent_at_date: string }) => {
    if (apiRequestCallId === this.getSendEmailData) {
      if (!responseJson.error) {
        this.setState({ vendorDetails: responseJson.emails });
        this.setState({ lastedEmail: responseJson.latest_sent_at_date });
      }
    }    
  };

  downloadSummaryResponse = async (apiRequestCallId: string, responseJson: {[key:string]:string}) => {
    if (apiRequestCallId === this.downloadSummaryPage) {
      if (responseJson) {
        this.setState({ pdfUrl: responseJson.pdf_url});
        this.setState({ pdfName: responseJson.pdf_filename });
      }
    }
  };
  
  downloadPdfResponse = async (apiRequestCallId: string, responseJson: {[key:string]:string}) => {
    if (apiRequestCallId === this.downloadQuote) {
      if (responseJson) {
        this.setState({ invoicePdfUrl: responseJson.pdf_url});
      }
    }
  };


  downloadPdfQuote = async (apiRequestCallId: string, responseJson: {[key:string]:string}) => {
    if (apiRequestCallId === this.downloadQuotePdf) {
      if (responseJson) {
        this.setState({ quotePdfUrl: responseJson.pdf_url});
      }
    }
  };

  checkBoxFun=(responseJson:any)=>{
    return  this.state.payReportid ? this.state.checkedBox  : responseJson.invoices_details[0]?.status === "true"
  }

  reportIdFun=(responseJson:any)=>{
    return  !this.state.payReportid ?  responseJson.invoices_details[0]?.id : this.state.payReportid
  }

  handleSeriesDataFormatting = (responseJson: any) => {
    if (responseJson.status === 'overcharged') {
      this.setState({
        series: [responseJson.status_percentage, 0]
      })
    } else if (responseJson.status === 'undercharged') {
      this.setState({
        series: [0, responseJson.status_percentage]
      })
    } else {
      this.setState({ series: [0, 0] })
    }
  }

  discrepancyApiCall = (value: any) => {
    this.setState({
      loadingVendorData: true,
    })
    const header = {
      token: document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.discrepancyDataApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.discrepancyApiEndPoint}?id=${value === 0 ? "all" : value}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  formatNumber = () => `$ ${this.state.vendorChangeReportData.total_discrepancy}`

  formattedTDNumber = (number: any) => {
    const result = Number(parseFloat(number).toFixed(0));
    const formatNumber = new Intl.NumberFormat('en-IN', {
      maximumFractionDigits: 2,
    }).format(result);
    return formatNumber
  }

  pdfFormToggleChangeHandler = () => {
    this.setState((prevState) => ({
      toggleValue: !prevState.toggleValue
    }))
  }

  handleQuoteResize = () => {
    if (this.state.fullViewScreen == "") {
      this.setState({
        halfViewScreen: "none",
        fullViewScreen: "block",
        quoteSize:1000
      })
    }
    else {
      this.setState({
        halfViewScreen: "",
        fullViewScreen: "",
        quoteSize:612
      })
    }
  }

  handleInvoiceResize = () => {
    if (this.state.fullViewScreen == "") {
      this.setState({
        halfViewScreen: "block",
        fullViewScreen: "none",
        invoiceSize:1000
      })
    }
    else {
      this.setState({
        halfViewScreen: "",
        fullViewScreen: "",
        invoiceSize:612
      })
    }
  }

  viewReportDetails = (id: number) => {

    this.setState({ isLoadingg: true, reportDataid: id });
    const header = {
      token: this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewReportApiCallID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reportDetailsApi}${id ? id : this.state.reportName}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType 
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  handlePrevPageQuotePdf = () => {
    const { pageNumberQuote, minPage } = this.state;
    if (pageNumberQuote > minPage && pageNumberQuote > 1) {
      this.setState({ pageNumberQuote: pageNumberQuote - 1 });
    }
  };

  handleNextPageQuotePdf = () => {
    const { pageNumberQuote } = this.state;
    if (pageNumberQuote < this.state.quotePdfpage) {
      this.setState({ pageNumberQuote: pageNumberQuote + 1 });
    }
  };

  handleTabChange = (event: any, newValue: number) => {
    const idPayreport = this.state.invoiceData[newValue]
    const addSwichcheckbox = this.state.invoiceData.find((value:{id:number})=>{return  value.id==idPayreport.id}).status=="true"
    this.setState({checkedBox:addSwichcheckbox,payReportid:idPayreport?.id})
    this.setState({ invoiceValue: newValue })
    this.setState({ pageNumberInvoice: 1 })
  }

  handlePrevPageInvoicePdf = () => {
    const { pageNumberInvoice, minPage } = this.state;
    if (pageNumberInvoice > minPage && pageNumberInvoice > 1) {
      this.setState({ pageNumberInvoice: pageNumberInvoice - 1 });
    }
  };

  handleNextPageInvoicePdf = () => {
    const { pageNumberInvoice } = this.state;
    if (pageNumberInvoice < 3) {
      this.setState({ pageNumberInvoice: pageNumberInvoice + 1 });
    }
  };

  handleInvoicePageChange = (event: unknown, newPage: number,paginationKey:any) => {
    const updatedPaginationArr = this.state.paginationArr.map((paginationObj:any) => {
      if (paginationObj.hasOwnProperty(paginationKey)) {
        return {
          ...paginationObj,
          [paginationKey]: newPage
        };
      }
      return paginationObj;
    });


    this.setState({ invoicePage: newPage,paginationArr: updatedPaginationArr,paginationKey})
  };

  handleQuotePageChange = (event:unknown, newPage: number,columnId: number) => {
    this.setState(prevState => ({
      quotePage: {
        ...prevState.quotePage,
        [columnId]: newPage 
      }
    }));
  };

  TruncatedTypography = (text:string, limit: number) => {
    text = text  ? text : "Quote"
    return text.length > limit ? text.substring(0, limit) + '...' : text;

  }

  onPageLoadSuccess = async (page: CustomPage) => {
    const textContent = await page.getTextContent();
    this.setState({ textItems: textContent.items || [] });
  };

  getNavigationScaninvoice = async() => {
    await setStorageData("itemsData",JSON.stringify(this.state.reportDetailsData))
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),'InvoiceScanResult');
    message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(message);
  }

  getCheckboxStyle = (webStyle:{checkboxChecked:{color:string},checkboxDefault:{color:string}}) => {
    return this.state.checkedBox ? webStyle.checkboxChecked : webStyle.checkboxDefault;
  };

  getLabelStyle = (webStyle:{labelChecked:{color:string},labelDefault:{color:string}}) => {
    return this.state.checkedBox ? webStyle.labelChecked : webStyle.labelDefault;
  };


  getLabelTect = () => {
    return this.state.checkedBox ? 'Approved to pay' : 'Approve for payment';
  };

  handleChangeCheckbox = async(event:React.ChangeEvent<HTMLInputElement>) => {
    const payValue = event.target.checked
    this.setState({ checkedBox: payValue });  
    const header = {
      token: this.getToken(),
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.approvedpaymentApiCallid = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.approvedPaymentstatus}?id=${this.state.payReportid}&payment=${payValue}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getNavigationViewreport = async() =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),'Viewreport');
    message.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(message);
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      this.setState({isLoadingg :true})
      this.setState({ files: Array.from(event.target.files)},()=> this.onInvoiceReportDrop(this.state.files));
    }
  };
  
  downloadFilee = async (url: string) => {
    const response = downloadFile(url);
    return response;
  };
  handleDownload = async () => {
    const specificFile = this.state.invoiceData[this.state.invoiceValue];
    const response = await this.downloadFilee(this.state.invoicePdfUrl);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const reportLink = document.createElement("a");
    reportLink.href = blobUrl;
    reportLink.download = specificFile?.project;
    document.body.appendChild(reportLink);
    reportLink.click();
    document.body.removeChild(reportLink);
    window.URL.revokeObjectURL(blobUrl);
  };
  quoteDownload = async () => {
    const specificFileName = this.state.quotesTabs[0].project;
    const response = await this.downloadFilee(this.state.quotePdfUrl);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const reportLink = document.createElement("a");
    reportLink.href = blobUrl;
    reportLink.download = specificFileName;
    document.body.appendChild(reportLink);
    reportLink.click();
    document.body.removeChild(reportLink);
    window.URL.revokeObjectURL(blobUrl);
  };

  summaryPdfDownload = async () => {
    const response = await this.downloadFilee(this.state.pdfUrl);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const reportLink = document.createElement("a");
    reportLink.href = blobUrl;
    reportLink.download = "discrepancy_summary";
    document.body.appendChild(reportLink);
    reportLink.click();
    document.body.removeChild(reportLink);
    window.URL.revokeObjectURL(blobUrl);
  };
  onInvoiceReportDrop = (files: Array<File>) => {
    const header = {
      "token": this.getToken(),
    };

    const reportQuotesFormData = new FormData();

    files.forEach((file: File, fileIndex: number) => {
      reportQuotesFormData.append(`files${fileIndex + 1}`, file);
    });

    const requestReportQuotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadReportInvoicesFileId = requestReportQuotesMessage.messageId;

    requestReportQuotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.invoiceUploadApiEndPoint}?vendor_id=${this.state.vendorValue}&project_id=${this.state.projectValue}&report_name=${this.state.reportName}`    );
    
      requestReportQuotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestReportQuotesMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reportQuotesFormData
    );
    requestReportQuotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestReportQuotesMessage.id, requestReportQuotesMessage);
    return true;
  };


  handleModalOpen = () => {
    this.setState({ openModal: true });
  };

  handleModalClose = () => {
    this.setState({ openModal: false });
    this.setState({ emailSubject: this.state.emailSubjectCancle },()=>this.updateButtonState());
    this.setState({ emailName: this.state.emailNameCancle },()=>this.updateButtonState());
    this.setState({ emailMessage: this.state.emailMessageCancle },()=>this.updateButtonState());
    this.setState({selectedValues: ["overcharge_only", "quote_highlighting", "invoice_highlighting"]})
    this.setState({ invalidEmail: "" });
  };

handleSelect = (value: string) => {
  this.setState(prevState => {
    let { selectedValues } = prevState;
    const isSelected = selectedValues.includes(value);

    if (value === "overcharge_only") {
      if (isSelected) {
        selectedValues = selectedValues.filter(
          item => item !== "overcharge_only" && item !== "over_and_undercharge"
        );
      } else {
        selectedValues = [...selectedValues, "overcharge_only"];
      }
    } else if (value === "over_and_undercharge") {
      if (selectedValues.includes("overcharge_only")) {
        if (isSelected) {
          selectedValues = selectedValues.filter(item => item !== "over_and_undercharge");
        } else {
          selectedValues = [...selectedValues, value];
        }
      }
    } else {
      selectedValues = isSelected
        ? selectedValues.filter(item => item !== value)
        : [...selectedValues, value];
    }

    return { selectedValues };
  });
};
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget, isOptionMenu: !this.state.isOptionMenu });
  };
  TruncatedInvoice = (text:string) => {
    text = text  ? text : "Invoice"
    return text;
  }

  handleNavigation = () => {
    const descrepency = { id: this.state.project_id, reportName: this.state.reportName, project: this.state.project_name}
     setStorageData("dataViewreport",JSON.stringify(descrepency))
    const navMes: Message = new Message(getName(MessageEnum.NavigationMessage));
    navMes.addData(getName(MessageEnum.NavigationTargetMessage), "viewDiscrepancySummary");
    navMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navMes);
  };
  handleProjNavigation = () => {
    const navMes: Message = new Message(getName(MessageEnum.NavigationMessage));
    navMes.addData(getName(MessageEnum.NavigationTargetMessage), "ProjectS");
    navMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navMes);
  };
  handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>,) => {
    const selectedValuee = event.target.value;
    this.setState({ selectedValue: selectedValuee as string }, () => {
      this.viewListFilter();
    });
  };
  getDiscrepancyInfo = () => {
    if (!this.state.dummyData) {
      return {
          displayText: 'No data available',
          backgroundColor: '#FFFFFF'
      };
  }
    const { total_discrepancy_undercharged, total_discrepancy_overcharged } = this.state.dummyData;
    let displayText = '';
    let backgroundColor = '';
    if (this.state.dummyData.total_discrepancy_overcharged > this.state.dummyData.total_discrepancy_undercharged) {
      displayText = `Overcharged $${this.state.dummyData.total_discrepancy_overcharged}`;
      backgroundColor = "#FB5D5D80";
    } else if (total_discrepancy_undercharged > total_discrepancy_overcharged) {
      displayText = `Undercharged $${total_discrepancy_undercharged}`;
      backgroundColor = "#FFF3C2";
    }
    return { displayText, backgroundColor };
  };
  handleChangeEmail = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ emailName: event.target.value },()=>this.updateButtonState());
    
  };

  handleChangeSubject = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ emailSubject: event.target.value },()=>this.updateButtonState());
  };

  handleChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ emailMessage: event.target.value },()=>this.updateButtonState());
  };

  getSummary = (itemId: number) => {
    const header = {
      token: this.getToken()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getIpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.summaryApi}${itemId ? itemId : this.state.reportName}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  calculateXSValue = (data: DataItem[]) => {
    return data.some((item: DataItem) => item?.tax !== null) ? 4 : 5;
  }

handlOpenPdf = () => {
this.setState({ showPdf: true, dowmloadModal: false });
this.setState({selectedOption: ""});
};

handleButtonClick = () => {
  if (this.state.quoteDown) {
    this.quoteDownload();
    this.setState({ quoteDown: false });
    this.setState({ selectedDownloadOption: "" });
  }
  if (this.state.invoiceDown) {
    this.handleDownload();
    this.setState({ invoiceDown: false });
    this.setState({ selectedDownloadOption: "" });
  }
}

  handleOpenDownloadModal = () => {
    this.setState({ dowmloadModal: true });
  };
  handleOpenDown = () => {
    this.setState({ quoteDown: true });
  };

  handleInvoice = () => {
    this.setState({ invoiceDown: true });
  }
  handleCloseDownloadModal = () => {
    this.setState({ dowmloadModal: false });
    this.setState({ invoiceDown: false });
    this.setState({ quoteDown: false });
    this.setState({ quoteDown: false });
    this.setState({ selectedOption: "" });
    this.setState({ selectedDownloadOption: "" });
  };

handleClosePdf = () => {
  this.setState({ showPdf: false });
};
  
  viewListFilter = () => {
    const header = {
      token: this.getToken()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.filterApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.filterSummaryApi}${this.state.selectedValue}&id=${this.state.project_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getEmailList = (itemId: number) => {
    const header = { token: this.getToken() };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEmailData = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.emailListApi}${itemId ? itemId : this.state.reportName}/email_report_details`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSendEmailList = (itemId: number) => {
    const header = { token: this.getToken() };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSendEmailData = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.emailListApi}${itemId ? itemId : this.state.reportName}/sent_email_data`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  downloadSummary = (itemId: number) => {
    const header = { token: this.getToken() };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.downloadSummaryPage = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_reports/reports/generate_discrepancy_report?discrepancy_type=${this.state.apiSelectedOption}&id=${itemId ? itemId : this.state.reportName}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)); 
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  downloadPdf = (itemId: number) => {
    const header = { token: this.getToken() };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.downloadQuote = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.downloadPdf}?id=${itemId}&pdf_type=invoice&report_type=${this.state.apiSelectedDownloadOption}&pdf_index=${this.state.invoiceValue}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)); 
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  downloadQuotesPdf = (itemId: number) => {
    const header = { token: this.getToken() };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.downloadQuotePdf = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.downloadPdf}?id=${itemId}&pdf_type=quote&report_type=${this.state.apiSelectedDownloadOption}&pdf_index=0`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)); 
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateVendor = () => {
    const header = { token: this.getToken() };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addVendorsId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.updateVentor}?vendor_name=${this.state.vendorName}&email=${this.state.emailName}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.editQuoteDetailAPIMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateButtonState = () => {
    const { emailName, emailSubject, emailMessage } = this.state;
    const isDisable = !((emailName ?? '').length > 0 && (emailSubject ?? '').length > 0 && (emailMessage ?? '').length > 0);
    this.setState({ isDisableBtn:  isDisable});
  };

  apiCall = async (data: APIPayloadType) => {
    const { method, endPoint, body, type = "" } = data;
    const token = this.getToken()
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData" ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      ) :
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage), body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }; 

  handleBackButton = () => {
    this.setState({ openModal: true, emailConfirm: false });
  };
  
  handleProceed = () => {
    this.updateVendor();
  };

  handleOpenConfirmModal = () => {
    const emailRegex = configJSON.emailReg;
    if (!emailRegex.test(this.state.emailName)) {
      this.setState({ invalidEmail: configJSON.emailError })

    }
    else {

      this.handleAddReports();
      this.setState({isDisableBtn: true});
    }
  };

  handleAddReports = async () => {
    const formdata = new FormData();
    formdata.append("to", this.state.emailName);
    formdata.append("subject", this.state.emailSubject);
    formdata.append("message", this.state.emailMessage);
    const includesOverAndUndercharge = this.state.selectedValues.includes("over_and_undercharge");

    for (let value of this.state.selectedValues) {
      if (value === "overcharge_only" && includesOverAndUndercharge) {
        continue;
      }
      formdata.append("discrepancy_types[][type]", value);
    }
    this.sendEmailApiCallId = await this.apiCall({
      contentType: configJSON.getUserListApiContentType,
      method: configJSON.editQuoteDetailAPIMethod,
      endPoint: `${configJSON.reportDetailsApi}${this.state.project_id ? this.state.project_id: this.state.reportName}/send_email_report`,
      body: formdata,
      type: "formData"
    })
  }

  handleListTab = (newValue: number, tabIndex: number) => {
    this.setState({ tabValue: newValue, activeTab: tabIndex});
  };
  handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
    const selectedValuee = event.target.value;
    if (selectedValuee == "Overcharge only report") {
      this.setState({ apiSelectedOption: "Overcharged" });
    } else {
      this.setState({ apiSelectedOption: "Both" });
    }
    this.setState({ selectedOption: event.target.value as string },()=> this.downloadSummary(this.state.project_id));
  };

  handlePdfSelect = (event: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
    const selectedValues = event.target.value;
    if (selectedValues == "Overcharge only report") {
      this.setState({ apiSelectedDownloadOption: "overcharge_only" });
    } else {
      this.setState({ apiSelectedDownloadOption: "overcharge_and_undercharge" });
    }
    this.setState({ selectedDownloadOption: event.target.value as string },()=> this.downloadPdf(this.state.project_id));
  };

  handlePdfQuote = (event: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
    const selectedValues = event.target.value;
    if (selectedValues == "Overcharge only report") {
      this.setState({ apiSelectedDownloadOption: "overcharge_only" });
    } else {
      this.setState({ apiSelectedDownloadOption: "overcharge_and_undercharge" });
    }
    this.setState({ selectedDownloadOption: event.target.value as string },()=> this.downloadQuotesPdf(this.state.project_id));
  };

  handleCheckBox = () => {
    this.setState({ isOptionMenu: false });
  };

  handleCloseToastMessage = () => {
    this.setState({ toastMessage: false });
  };
  segmentsArray(data: any[]) {
    return data.map((item: { [x: string]: any[]; }) => {
      const segments = Object.keys(item);
      return segments.map((segment) => {
        const hasTax = item[segment].some((entry: { tax: any; }) => entry.tax > 0);
        const segmentTotalAmount = item[segment].reduce((total, entry) => {
          return total + parseFloat(entry.amount || 0); 
        }, 0);
        return {
          segment: segment,
          hasTax: hasTax,
          segmentTotalAmount,
        };
      });
    });
  }

  createPaginationArr = (invoiceDescriptionGroup:any)=>{
    const paginationArr = invoiceDescriptionGroup.map((group:any,groupIdx:number) => {
      const paginationObject:any = {};
      let paginationCounter = 1;
  
      for (const key in group) {
        if (group[key] && Array.isArray(group[key])) {
          paginationObject[`invoice_${groupIdx+1}_pagination_${paginationCounter}`] = 1;
          paginationCounter++;
        }
      }
  
      return paginationObject;
    });

    this.setState({
      paginationArr
    })
  }

  handleChangePageInvoiceWrapper = (paginationKey:any) => {
    return (event: unknown, newPage: number) => {
      this.handleInvoicePageChange(event, newPage, paginationKey);
    };
  };

  viewHandleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ pdfSelectedRadioValue: event.target.value });
  };

  viewValueFromData = (datavalue:string) =>{
      return datavalue ? datavalue : "-";
  };

  viewValueZeroFromData = (datavalue:string) =>{
    return datavalue ? datavalue : "0";
  };

  renderRatesheetText = (valuetext:string) => {
    const { dayTitle, weekTitle, monthTitle } = configJSON;

    let frequencyText = '';
    if (valuetext === dayTitle) {
        frequencyText = 'Daily';
    } else if (valuetext === weekTitle) {
        frequencyText = 'Weekly';
    } else if (valuetext === monthTitle) {
        frequencyText = 'Monthly';
    }
    return frequencyText;
  }

  invoiceColor = (borderHighlights:Object,colorBorder:Object,deafultColor:Object) => {
  return borderHighlights ? colorBorder : deafultColor
  }
  // Customizable Area End
}
