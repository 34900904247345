// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import {
 TableCell
} from "@material-ui/core";
export interface DataItemQuote {
  id: number;
  description: string;
  unit: string;
  ord_quantity: number;
  amount: string;
  price: string;
  created_at: string;
  report_quote_id: number;
  part_number: string | null;
  updated_at: string;
  category_name: string;
  tax: string | null;
  rate_sheet_cat: string | null;
  category_subtotal: number;
  rate_sheet_daily: string | null;
  rate_sheet_min: string | null;
  rate_sheet_monthly: string | null;
  rate_sheet_weekly: string | null;
  cat_class: string | null;
}
// Customizable Area End

export const configJSON = require("./config");


interface columnQuote {
  id: string;
  label: string;
}

interface quoteDetailData {
  subtotal: string,
  expiration_date: string,
  date: string,
  quote_id: string,
  dateVal: string,
  expiryDateVal: string,
  site_name: string,
  supplier_address: string,
  supplier_salesperson: string,
  supplier_contact: string,
  project: string,
  site_address: string,
  site_contactperson: string,
  site_contact: string,
}
export interface Props {
  // Customizable Area Start
  classes: any;
  navigation: any;
  id: string;
  handleSaveData: (data: any) => void;
  location: any;
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  viewResultRadioValue:string,
  typeArraysObject:boolean,
  quoteComparisonData: any[],
  quoteDetailData: quoteDetailData[],
  quotePage: any,
  quoteRowsPerPage: number,
  quoteColumn: columnQuote[],
  reportName: string;
  projectNmae: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class QuoteScanResultontroller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      viewResultRadioValue:'A day',
      typeArraysObject : false,
      quoteComparisonData: this.props.location?.state?.Compare_Difference,
      quoteDetailData: [],
      quotePage: 1,
      quoteRowsPerPage: 10,
      reportName:"",
      projectNmae: "",
      quoteColumn: [
        {
          id: 'Sr.no', label: 'Sr. No',
        },
        {
          id: 'description', label: 'Description',
        },
        {
          id: 'OrderQty.', label: 'Order Qty.',
        },
        {
          id: 'unit', label: 'Unit',
        },
        {
          id: 'unitPrice', label: 'Unit Price',
        },
        {
          id: 'tax', label: 'Tax (%)',
        },
        {
          id: 'Ext. Price', label: 'Ext. Price'
        },
      ]
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const itemData= await getStorageData("dataViewreport");
    const parseItemdata = JSON.parse(itemData);
    const {reportDetailsData} = this.props.location.state
    this.props.location.state.viewQuotescanretrun ? this.setState({projectNmae: parseItemdata.project,quoteDetailData:reportDetailsData.data.attributes.quote, quoteComparisonData:reportDetailsData.data.attributes.quote_description,reportName:reportDetailsData.data.attributes.report_name,typeArraysObject:Array.isArray(reportDetailsData.data.attributes.quote_description) }):
    this.setState({ projectNmae: parseItemdata.project ,quoteDetailData: reportDetailsData.reports_details.data.attributes.quote, quoteComparisonData:reportDetailsData.reports_details.data.attributes.quote_description, reportName:reportDetailsData.reports_details.data.attributes.report_name,typeArraysObject:Array.isArray(reportDetailsData.reports_details.data.attributes.quote_description)})
  }


  handleChangePage = (event: unknown, newPage: number,columnId: number | string) => {
      this.setState(prevState => ({
      quotePage: {
        ...prevState.quotePage,
        [columnId]: newPage 
      }
    }));
  };

  goBackhandler = () => {
    this.props.history.push('/viewreportdetails')
  }

  handleWaterSupplyPdf = (temp: string[], idCounter: number) => {
    
    const unitAndPrice = temp[temp.length - 2].split('/');
    let description = temp[1];

    if (temp.length > 4) {
      description += " " + temp.slice(2, -2).join(" ");
    }

    return {
      id: idCounter,
      quantity: parseFloat(temp[0]),
      description,
      unit: unitAndPrice[1],
      unit_price: `$ ${parseFloat(temp[temp.length - 2])}`,
      price: `$ ${parseFloat(temp[temp.length - 1])}`
    };
  }

  handlePaceSupplyPdf = (temp: string[], idCounter: number) => {
    let description = temp.slice(0, temp.length - 4).join(" ")
    return {
      id: idCounter,
      description,
      quantity: temp[temp.length - 3],
      unit: temp[temp.length - 2],
      unit_price: `$ ${temp[temp.length - 4]}`,
      price: `$ ${temp[temp.length - 1]}`
    };
  }

  handleWhiteCapPdf = (temp: string[], idCounter: number) => {
      let description = temp.slice(2, -2).join(" ");
      return {
        id: idCounter,
        description,
        quantity: temp[temp.length - 4],
        unit: temp[temp.length - 3],
        unit_price: temp[temp.length - 2],
        price: temp[temp.length - 1]
      };
    
  }

  handleEditQuote = () => {
    this.props.history.push('/report/editquotescanresult', {quotestate: this.state?.quoteComparisonData, quotedetail: this.state.quoteDetailData[0],quotescan:true,typeArraysObject:this.state.typeArraysObject})
  }


  // Customizable Area Start
  getNavigationViewreport = async () => {
    this.props.history.push("/viewreport")
  };

  goBack = () => {
    window.history.back();
  };

  renderQuoteStartDate = (darValue:string,retrunDatevalue:string) =>{
    return darValue == null ? 'Unknown' : retrunDatevalue;
  };
  
  renderQuoteexpiryDateVal = (datevalue:string,reportexpieydate:string) =>{
    return datevalue == null ? 'Unknown' : reportexpieydate;
  }

  segmentTotalRetrun = (value:string) =>{
    return value ? configJSON.invoiceCategoryTotal : configJSON.segmentContent;
  }

  renderRatesheetScanResult = (valuetext:string) => {
    const { dayTitle, weekTitle, monthTitle } = configJSON;

    let frequencyText = '';
    if (valuetext === dayTitle) {
        frequencyText = 'Daily';
    } else if (valuetext === weekTitle) {
        frequencyText = 'Weekly';
    } else if (valuetext === monthTitle) {
        frequencyText = 'Monthly';
    }
    return frequencyText;
  }

  viewHandleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ viewResultRadioValue: event.target.value });
  };

  viewScanValueZeroFromData = (datavalue:string|null) =>{
    return datavalue ? datavalue : "0";
  };

  renderRateSheetDay = (quoteData:DataItemQuote) => {
    switch (this.state.viewResultRadioValue) {
      case configJSON.dayTitle:
        return quoteData?.rate_sheet_daily;
      case configJSON.weekTitle:
        return quoteData?.rate_sheet_weekly;
      case configJSON.monthTitle:
        return quoteData?.rate_sheet_monthly;
      default:
        return null;
    }
  };

  taxHeader = (trueVAlue:boolean,taxValue:string|null) =>{
   return trueVAlue && <TableCell>{this.viewScanValueZeroFromData(taxValue)}%</TableCell>
  }

  renderSubtotal = (paginatedData:string | null, totalVal:string) => {
    return paginatedData ? paginatedData : totalVal
  };
  // Customizable Area End
}
